<template>
	<div class="Sketchtodescribe">
        <div class="sket_div">
            <ul class="sket_div_ul clearfix">
                <li class="fl" v-for="(item,index) in srcList" :key="index">
                    <span class="li_span_img">
                        <i class="el-icon-zoom-in" @click="dk_Dt(item)"></i>
                    </span>
                    <el-image :src="item.value" fit="scale-down" ></el-image>
                    <div class="demo_span">{{item.userName}}</div>
                </li>
            </ul>
        </div>
        <div class="caotu_popup">
          <el-dialog
                title="查看大图"
                :visible.sync="dialogVisible"
                width="800px"
                :before-close="handleClose"
                :show-close="false"
                top="10vh"
          >
          <i class="el-icon-close closei" @click="closei()"></i>
          <div class="caotu_img">
            <img :src="imgUrl"  alt="">
          </div>
          </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
	data() {
		return {
            forms:{
                p1:{},
            },
            srcList:[],
            imgUrl:'',
            dialogVisible:false
		}
	},
	methods: {
        getapp(){
            this.calId=this.$route.query.calId
            this.axios.projects5({
                token:this.$store.state.token,
                id:this.calId,
                tId:this.$store.state.tem_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                    this.forms = res.data.data.data
                    this.srcList = this.forms.p1.values
                    var str = this.form
                    this.$emit('info', str);
                }else{
                    this.$message.error(res.data.message)
                    this.form = {}
                }
            }).catch(err=>{

            })
        },
        dk_Dt(val){
            this.imgUrl = val.value
            this.dialogVisible = true
        },
        closei(){
            this.dialogVisible = false
        },
        handleClose(done){

        }
    },
	mounted() {
        this.getapp()
	}
}
</script>
<style scoped>
@import './../../../assets/css/teacher/Copypaper/CognitiveCTMS.css';
</style>
<style>
.sket_div_ul .el-image{
  width: 100%;
  height: calc(100% - 30px);
}
/* 弹窗 */
.caotu_popup .el-dialog__footer{
    text-align: center;
}
.caotu_popup .el-form{
    text-align: center;
}
.caotu_popup .el-dialog__header {
   padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.caotu_popup .el-dialog__body{
    background-color: #fff;
}
.caotu_popup .el-dialog__title {
  font-size: 16px;
    font-weight: bold;
    color:#fff;
}
.caotu_popup .el-dialog__headerbtn .el-dialog__close {
  color: white;
}
.caotu_popup .el-dialog__headerbtn {
  top: 15px;
}
</style>